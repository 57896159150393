<template>
    <v-card flat>
        <app-loader :value="$apollo.loading"/>
        <v-container>
            <v-data-table
                    :headers="headers"
                    :items="orders"
                    disable-sort
            >
                <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-toolbar-title>All Orders</v-toolbar-title>
                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        ></v-divider>
                    </v-toolbar>
                    <v-form v-model="valid">
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-menu
                                        v-model="dateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="filters.date"
                                                label="Filter by start date"
                                                prepend-inner-icon="mdi-calendar"
                                                readonly
                                                clearable
                                                v-bind="attrs"
                                                outlined
                                                rounded
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.date" @input="dateMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                    outlined
                                    clearable
                                    rounded
                                    v-model="filters.statuses"
                                    label="Filter by status"
                                    multiple
                                    :items="statuses"
                                ></v-select>
                            </v-col>
                          <v-col cols="12" md="3">
                                <v-menu
                                        v-model="datePlacedMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="filters.datePlaced"
                                                label="Filter by date placed"
                                                prepend-inner-icon="mdi-calendar"
                                                readonly
                                                clearable
                                                v-bind="attrs"
                                                outlined
                                                rounded
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.datePlaced" @input="datePlacedMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                        outlined
                                        label="Search"
                                        hint="Search by customer name, email address or order number"
                                        rounded
                                        prepend-inner-icon="mdi-magnify"
                                        v-model="filters.search"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </template>
                <template v-slot:item.customer="{ item }">
                    <div class="py-5">
                        <div>
                            <v-icon small>mdi-account</v-icon>
                            {{ item.customer.name }}
                        </div>
                        <div>
                            <v-icon small>mdi-email</v-icon>
                            {{ item.customer.email }}
                        </div>
                        <div>
                            <v-icon small>mdi-phone</v-icon>
                            {{ item.customer.phoneNumber }}
                        </div>

                    </div>
                </template>
                <template v-slot:item.date="{ item }">
                    {{ item.collectionDate | niceTime }}
                </template>
                <template v-slot:item.datePlaced="{ item }">
                    {{ item.created | niceTime }}
                </template>
                <template v-slot:item.order="{ item }">
                    {{ item.hashId }}
                </template>
                <template v-slot:item.product="{ item }">
                    <ul class="pl-0">
                        <li style="list-style-type: none" v-for="prod in item.productNames" :key="prod">{{ prod }}</li>
                    </ul>
                </template>
                <template v-slot:item.actions="{ item }">
                    <router-link :to="`/orders/${item.id}`" v-slot="{ href }">
                        <a :href="href" class="primary-text" style="text-decoration: none">View details</a>
                    </router-link>
                </template>
            </v-data-table>
        </v-container>
    </v-card>
</template>

<script>
    import {orders} from '@/graphql'
    import moment from 'moment'

    export default {
        data: () => ({
            headers: [
                {text: 'Customer', value: 'customer'},
                {text: 'Order number', value: 'order'},
                {text: 'Status', value: 'status'},
                {text: 'Start date', value: 'date'},
                {text: 'Date placed', value: 'datePlaced'},
                {text: 'Type', value: 'hireType'},
                {text: 'Products', value: 'product'},
                {text: 'Actions', value: 'actions'}
            ],
            valid: null,
            dateMenu: false,
            datePlacedMenu: false,
            filters: {
                search: null,
                date: null,
                datePlaced: null,
                statuses: []
            },
            statuses: [
                { text: 'Pending', value: 'PENDING'},
                { text: 'Confirmed', value: 'CONFIRMED'},
                { text: 'Cancelled', value: 'CANCELLED'},
                { text: 'In progress', value: 'IN_PROGRESS'},
                { text: 'Completed', value: 'COMPLETED'},
                { text: 'Refunded', value: 'REFUNDED'},
            ]
        }),
        apollo: {
            orders: {
                query: orders,
                variables () {
                    const { search, date, statuses, datePlaced } = this.filters;
                    return { search, date, statuses, datePlaced}
                }
            }
        },
        watch: {
            filters () {
                this.$apollo.queries.orders.refetch()
            }
        },
        filters: {
            niceTime (val) {
                return moment.parseZone(val).calendar()
            }
        },
    }
</script>
