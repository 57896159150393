var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('app-loader',{attrs:{"value":_vm.$apollo.loading}}),_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("All Orders")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1),_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Filter by start date","prepend-inner-icon":"mdi-calendar","readonly":"","clearable":"","outlined":"","rounded":""},model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"outlined":"","clearable":"","rounded":"","label":"Filter by status","multiple":"","items":_vm.statuses},model:{value:(_vm.filters.statuses),callback:function ($$v) {_vm.$set(_vm.filters, "statuses", $$v)},expression:"filters.statuses"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Filter by date placed","prepend-inner-icon":"mdi-calendar","readonly":"","clearable":"","outlined":"","rounded":""},model:{value:(_vm.filters.datePlaced),callback:function ($$v) {_vm.$set(_vm.filters, "datePlaced", $$v)},expression:"filters.datePlaced"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePlacedMenu),callback:function ($$v) {_vm.datePlacedMenu=$$v},expression:"datePlacedMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.datePlacedMenu = false}},model:{value:(_vm.filters.datePlaced),callback:function ($$v) {_vm.$set(_vm.filters, "datePlaced", $$v)},expression:"filters.datePlaced"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Search","hint":"Search by customer name, email address or order number","rounded":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)],1)],1)]},proxy:true},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-5"},[_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(item.customer.name)+" ")],1),_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email")]),_vm._v(" "+_vm._s(item.customer.email)+" ")],1),_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-phone")]),_vm._v(" "+_vm._s(item.customer.phoneNumber)+" ")],1)])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("niceTime")(item.collectionDate))+" ")]}},{key:"item.datePlaced",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("niceTime")(item.created))+" ")]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hashId)+" ")]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('ul',{staticClass:"pl-0"},_vm._l((item.productNames),function(prod){return _c('li',{key:prod,staticStyle:{"list-style-type":"none"}},[_vm._v(_vm._s(prod))])}),0)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/orders/" + (item.id))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('a',{staticClass:"primary-text",staticStyle:{"text-decoration":"none"},attrs:{"href":href}},[_vm._v("View details")])]}}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }